import { useLoading } from 'components/Layout/Loading';
import createStore from 'hooks/hookStore';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ITerritory, ITerritoryForm, createEmptyTerritoryForm } from 'model/territories';
import { findTerritoriesByMarket, findTerritoryById, initiateSkipTraceForTerritory, saveTerritory } from 'api/territoriesApi';
import { findMarkets } from 'api/marketsApi';
import { IDropdownOption } from 'model/dropdown';
import { MarketUtils } from 'model/markets';
import useToast from 'components/toast/useToast';
import { prepTerritory } from 'api/neighborhoodLaunchApi';

const loadingKey = 'TerritoryDetails';
type TerritoryDetails = {
  formData: ITerritoryForm;
  markets: IDropdownOption[];
  lastSkipTracedTimestamp?:string | null;
}

const { get, update, registerListener, unregisterListener } = createStore<TerritoryDetails>('TerritoryDetails', {
  formData: createEmptyTerritoryForm(),
  markets: [],
});


export default function useTerritoryDetails() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { territoryId: territoryIdPathParam } = useParams();

  const { createErrorToast, createSuccessToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      update({
        ...get(),
        formData: createEmptyTerritoryForm(),
        markets: [],
      });
      unregisterListener(setState);
    };
  }, []);

  async function init(marketId: string, isNew:boolean) {
    onLoading();
    try {

      try {
        const marketRes = await findMarkets();
        const markets = MarketUtils.convertToDropdownOptions(marketRes.data);
        if (isNew) {

          update({
            ...get(),
            formData: createEmptyTerritoryForm(marketId),
            markets,
          });
        } else {
          const res = await findTerritoryById(marketId, territoryIdPathParam!);
          if (res.data) {
            update({
              ...get(),
              formData: convertForForm(res.data, marketId),
              markets,
              lastSkipTracedTimestamp: res.data.lastSkipTracedTimestamp,
            });
          }
        }

      } catch (err:any) {
        console.error(err);
      }

    } catch (e:any) {
      console.error(e);
    }
    doneLoading(300);
  }

  async function save(data: ITerritoryForm) {
    onLoading();
    try {
      const res = await saveTerritory(data.marketId, data.id, data);
    } catch (err:any) {
      console.error(err);
    }
    doneLoading(300);
  }

  function convertForForm(territory:ITerritory, marketId:string):ITerritoryForm {
    return {
      id: territory.id,
      name: territory.name,
      zipCodeTerritory: territory.zipCodeTerritory,
      status: territory.status,
      marketId,
      territoryGeometryWKT: territory.territoryGeometryWKT,
      supplyAcquisitionTargetlevel: territory.supplyAcquisitionTargetlevel,
      newsletterCadence: territory.newsletterCadence,
      nonUserNewsletterCadence: territory.nonUserNewsletterCadence,
    };
  }

  async function onConfirmTerritoryPrep() {
    onLoading();
    try {
      const { formData } = get();

      if (!formData || !formData.id) {
        createErrorToast('You must save the territory');
        doneLoading(300);
        return;
      }

      await prepTerritory(formData.id);
      createSuccessToast('Successfully initiated territory prep process!');
    } catch (e : any) {
      console.error(e);
      createErrorToast(`Error prepping territory: ${e.response?.data?.message}`);
    }
    doneLoading(300);
  }

  async function onConfirmSkiptraceData() {
    onLoading();
    try {
      const { formData } = get();

      if (!formData || !formData.id) {
        createErrorToast('You must save the territory');
        doneLoading(300);
        return;
      }
      await initiateSkipTraceForTerritory(formData.id);
      createSuccessToast('Successfully initiated territory skiptrace process!');
    } catch (e : any) {
      console.error(e);
      createErrorToast(`Error skip tracing territory: ${e.response?.data?.message}`);
    }
    doneLoading(300);
  }

  function isPrepTerritoryEnabled() {
    const { formData } = get();
    return formData && formData.id && formData.newsletterCadence && formData.zipCodeTerritory && formData.territoryGeometryWKT;
  }

  return {
    ...get(),
    loadingKey,
    init,
    save,
    onConfirmTerritoryPrep,
    onConfirmSkiptraceData,
    isPrepTerritoryEnabled,
  };
}


